import * as events from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';

export default function init() {
  const SELECT_CLASSES = 'js-select-change';
  const SELECT_ELEMS = document.getElementsByClassName(SELECT_CLASSES);

  if (!SELECT_ELEMS.length) {
    return false;
  }

  for (const item of SELECT_ELEMS) {
    handleChange(item);
  }
}

function handleChange(select) {
  events.on(select, 'change', redirect);
}

function redirect(e) {
  const IS_PAGER = readAttribute(e.target, 'data-mode');
  const option = e.target.options[e.target.selectedIndex];
  let path;

  if (IS_PAGER) {
    const currentPath = window.location.href;
    const index = readAttribute(option, 'data-index');
    const param = index === 1 ? '' : `?page=${index}`;

    path = currentPath.indexOf('?')
      ? currentPath.split('?')[0] + param
      : currentPath + param;
  } else {
    path = e.target.value;
  }

  window.location.href = path;
}
