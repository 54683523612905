import roller from 'common/module/ui/roller';
import loadEmbed from 'common/tools/ui/load-embed';

import applicationBootstrap from 'website/bootstrap';
import { socialActionsBootstrap } from 'website/bootstrap/socialActionsBootstrap';
import comments from 'website/containers/generic/comments';
import diaporama from 'website/containers/slideshow/slideshow';
import userRating from 'website/containers/user-action/userRating';
import userWantToSee from 'website/containers/user-action/userWantToSee';
import affinitySuggestions from 'website/containers/user/affinitySuggestions';
import loadAdgage from 'website/module/advertisement/adgage';
import loadMgid from 'website/module/advertisement/mgid';
import loadOutbrain from 'website/module/advertisement/outbrain';
import loadTaboola from 'website/module/advertisement/taboola';
import emergenceBanner from 'website/module/emergence/banner-bottom';
import initPlayer from 'website/module/player';
import carousel from 'website/module/ui/carousel';
import facebookWidgets from 'website/module/ui/facebookWidgets';
import selectChange from 'website/module/ui/select-change';

// bootstrap the application / store
applicationBootstrap().then(() => {
  initPlayer();
  diaporama();

  loadEmbed();

  loadOutbrain();

  loadAdgage();

  loadTaboola();

  loadMgid();

  comments();

  facebookWidgets();

  carousel();

  userRating();

  roller();

  selectChange();

  emergenceBanner();

  userWantToSee();
  affinitySuggestions();
  socialActionsBootstrap();
});
