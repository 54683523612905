import loadScript from 'load-script';

import readAttribute from 'common/tools/dom/readAttribute';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

export default function loadMgid() {
  const mgidPlace = document.getElementsByClassName('js-mgid');
  if (!mgidPlace.length) {
    return false;
  }

  const MGID_URL = readAttribute(mgidPlace[0], 'data-url');

  if (!MGID_URL) {
    return false;
  }

  runAfterDeferAssets(() => {
    loadScript(MGID_URL);
  });
}
