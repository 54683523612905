import loadApesterApi from 'common/third_party/apester';
import loadFacebookApi from 'common/third_party/facebook';
import loadImgurApi from 'common/third_party/imgur';
import loadInstagramApi from 'common/third_party/instagram';
import loadRiddleApi from 'common/third_party/riddle';
import loadTiktokApi from 'common/third_party/tiktok';
import loadTwitterApi from 'common/third_party/twitter';
import { runAfterDesobfuscation } from 'common/tools/seo/desobfuscation';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

function loadEmbedApi() {
  const embed = document.getElementsByClassName('js-async-embed');

  if (!embed.length) {
    return false;
  }

  const functionsSet: Set<() => void> = new Set();

  for (const widget of embed) {
    const type = widget.getAttribute('data-type');

    switch (type) {
      case 'tw-embed':
        functionsSet.add(loadTwitterApi);
        break;
      case 'fb-embed':
        functionsSet.add(loadFacebookApi);
        break;
      case 'insta-embed':
        functionsSet.add(loadInstagramApi);
        break;
      case 'imgur-embed':
        functionsSet.add(loadImgurApi);
        break;
      case 'apester-embed':
        functionsSet.add(loadApesterApi);
        break;
      case 'riddle-embed':
        functionsSet.add(loadRiddleApi);
        break;
      case 'tk-embed':
        functionsSet.add(loadTiktokApi);
        break;
      default:
        break;
    }
  }

  for (const func of functionsSet) {
    runAfterDesobfuscation(func);
  }
}

export default function init() {
  runAfterDeferAssets(loadEmbedApi);
}
