import loadScript from 'load-script';

const IMGUR_WIDGETS_URL = '//s.imgur.com/min/embed.js';

let loaded = false;

/**
 * This module / function allows the dynamic loading of imgur widget
 * javascript library
 * @param  {Function} callback - the function to execute once twitter widget api is loaded
 * @param  {Object}   options  - options for the loading of the script
 * @return {void}
 */
export default function (callback = null, options = {}) {
  if (loaded && typeof callback === 'function') {
    callback();
    return false;
  }

  loadScript(IMGUR_WIDGETS_URL, options, () => {
    loaded = true;
    if (typeof callback === 'function') {
      callback();
    }
  });
}
