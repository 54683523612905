import loadScript from 'load-script';

const APESTER_SDK_URL = `https://static.apester.com/js/sdk/latest/apester-sdk.js`;

let loaded = false;

/**
 * This module / function allows the dynamic loading of apester sdk
 * javascript library
 * @param  {Function} callback - the function to execute once apester widget api is loaded
 * @param  {Object}   options  - options for the loading of the script
 * @return {void}
 */
export default function (callback = null, options = {}) {
  if (loaded && typeof callback === 'function') {
    callback();
    return false;
  }

  loadScript(APESTER_SDK_URL, options, () => {
    loaded = true;
    if (typeof callback === 'function') {
      callback();
    }
  });
}
